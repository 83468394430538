<template>
  <header
    id="header"
    class="header header-box-shadow-on-scroll header-bg-transparent header-abs-top header-show-hide"
    data-hs-header-options="{
      &quot;fixMoment&quot;: 1000,
      &quot;fixEffect&quot;: &quot;slide&quot;
    }"
  >
    <div class="header-section">
      <div id="logoAndNav" class="container">
        <!-- Nav -->
        <nav class="js-mega-menu navbar navbar-expand-lg">
          <!-- Logo -->
          <router-link class="navbar-brand" :to="{ path: '/' }" aria-label="beNovelty">
            <img src="@/assets/svg/logos/logo.svg" alt="beNovelty">
          </router-link>
          <!-- End Logo -->

          <!-- Responsive Toggle Button -->
          <!-- <button
            id="navHideButton"
            :aria-expanded="menu_expanded"
            type="button"
            class="navbar-toggler navbar-nav-wrap-navbar-toggler btn btn-icon btn-sm rounded-circle"
            aria-label="Toggle navigation"
            aria-controls="navBar"
            data-toggle="collapse"
            data-target="#navBar"
            @click="expandMenu"
          > -->
          <button
            id="navHideButton"
            :aria-expanded="menu_expanded"
            type="button"
            class="navbar-toggler navbar-nav-wrap-navbar-toggler btn btn-icon btn-sm rounded-circle"
            aria-label="Toggle navigation"
            aria-controls="navBar"
            @click="expandMenu"
          >
            <span class="navbar-toggler-default">
              <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z" />
              </svg>
            </span>
            <span class="navbar-toggler-toggled">
              <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </span>
          </button>
          <!-- End Responsive Toggle Button -->

          <!-- Navigation -->
          <div id="navBar" class="navbar-nav-wrap-navbar collapse navbar-collapse">
            <div class="navbar-body header-abs-top-inner">
              <ul class="navbar-nav">

                <!-- Features -->
                <li class="hs-has-sub-menu navbar-nav-item">
                  <a id="featuresMegaMenu" class="hs-mega-menu-invoker nav-link mr-lg-3" href="javascript:;" aria-haspopup="true" aria-expanded="false" aria-labelledby="featuresSubMenu">Features <i class="fas fa-angle-down ml-2" /></a>
                  <div id="featuresSubMenu" class="hs-sub-menu dropdown-menu" aria-labelledby="featuresMegaMenu" style="min-width: 230px;">
                    <router-link class="dropdown-item" :to="{ path: '/api-portal' }" @click.native="hideMenu('featuresMegaMenu')">API Developer Portal</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/api-management' }" @click.native="hideMenu('featuresMegaMenu')">API Management</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/api-monetization' }" @click.native="hideMenu('featuresMegaMenu')">API Monetization</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/subscription-management' }" @click.native="hideMenu('featuresMegaMenu')">Subscription Management</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/developer-management' }" @click.native="hideMenu('featuresMegaMenu')">Developer Management</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/api-transformations' }" @click.native="hideMenu('featuresMegaMenu')">API Transformations</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/apiops-cicd' }" @click.native="hideMenu('featuresMegaMenu')">APIOps & CI/CD Pipeline</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/trust-certification' }" @click.native="hideMenu('featuresMegaMenu')">Trust & Security</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/themes' }" @click.native="hideMenu('featuresMegaMenu')">API Portal Themes</router-link>
                    <div class="dropdown-divider" />
                    <router-link class="dropdown-item" :to="{ path: '/ai-gateway' }" @click.native="hideMenu('featuresMegaMenu')">AI Gateway</router-link>
                  </div>
                </li>
                <!-- End Features -->

                <!-- Learn -->
                <li class="hs-has-sub-menu navbar-nav-item">
                  <a id="learnMegaMenu" class="hs-mega-menu-invoker nav-link mr-lg-3" href="javascript:;" aria-haspopup="true" aria-expanded="false" aria-labelledby="learnSubMenu">Learn <i class="fas fa-angle-down ml-2" /></a>
                  <div id="learnSubMenu" class="hs-sub-menu dropdown-menu" aria-labelledby="learnMegaMenu" style="min-width: 230px;">
                    <a class="dropdown-item" href="https://www.openapihub.com/academy/" target="_blank" @click.native="hideMenu('learnMegaMenu')">Open API Academy <i class="fas fa-external-link-alt small ml-1" /></a>
                    <a class="dropdown-item" href="https://blog.openapihub.com/" target="_blank" @click.native="hideMenu('learnMegaMenu')">API Blog <i class="fas fa-external-link-alt small ml-1" /></a>
                    <a class="dropdown-item" href="https://guide.fabrixapi.com/en-us/" target="_blank" @click.native="hideMenu('learnMegaMenu')">User Guide <i class="fas fa-external-link-alt small ml-1" /></a>
                    <a class="dropdown-item" href="https://www.openapihub.com/community-updates" target="_blank" @click.native="hideMenu('learnMegaMenu')">Community Updates <i class="fas fa-external-link-alt small ml-1" /></a>
                  </div>
                </li>
                <!-- End Learn -->

                <li class="header-nav-item">
                  <router-link class="nav-link header-nav-link mr-0 mr-lg-3" :to="{ path: '/pricing' }" @click.native="hideMenu('featuresMegaMenu')">Pricing</router-link>
                </li>

                <li class="header-nav-item">
                  <router-link class="nav-link header-nav-link mr-0 mr-lg-3" :to="{ path: '/contact' }" @click.native="hideMenu('featuresMegaMenu')">Contact</router-link>
                </li>

                <!-- <li class="navbar-nav-item navbar-button pl-0 pl-lg-2 pt-2 pt-lg-0">
                    <a id="ga-OW-CC01-08" href="https://hub.openapihub.com/" class="btn btn-sm btn-outline-primary btn-block d-lg-inline-block" target="_blank">
                      {{ $t('Header.APIHub') }}
                    </a>
                  </li> -->

                <li class="navbar-nav-item navbar-nav-last-item">
                  <a class="btn btn-sm btn-primary" href="https://provider-portal.fabrixapi.com/" target="_blank">Log in / Register</a>
                </li>
              </ul>
            </div>
          </div>
          <!-- End Navigation -->
        </nav>
        <!-- End Nav -->
      </div>
    </div>
  </header>
</template>

<script>
const $ = require('jquery')
import HSHeader from '../assets/vendor/hs-header/src/js/hs-header'
import HSMegaMenu from '../assets/vendor/hs-mega-menu/src/js/hs-mega-menu'
import HSGoTo from '../assets/vendor/hs-go-to/src/js/hs-go-to'

export default {
  name: 'Header',
  components: {
  },
  data() {
    return {
      menu_expanded: false
    }
  },
  async mounted() {
    $('#header').each(function() {
      new HSHeader($(this)).init()
    })
    $('.js-mega-menu').each(function() {
      new HSMegaMenu($(this)).init()
    })
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  methods: {
    scrollToFooter() {
      window.scrollTo({
        top: $('#footer').offset().top,
        behavior: 'smooth'
      })
    },
    expandMenu() {
      this.menu_expanded = !this.menu_expanded
      if (this.menu_expanded) {
        $('#navBar').slideDown('fast', function() { $('#navBar').addClass('show'); $('#navBar').attr('style', null) })
      } else {
        $('#navBar').slideUp('fast', function() { $('#navBar').removeClass('show'); $('#navBar').attr('style', null) })
      }
      // $('#navHideButton').attr('aria-expanded', 'true')
      // this.menu_expanded = !this.menu_expanded
      // console.log($('#navBar'))
      // $('#navBar').collapse('show')
    },
    hideMenu(menuLabel) {
      // const megaMenu = $("[aria-labelledby='useCasesMegaMenu']")
      // console.log(megaMenu)
      // megaMenu.css('display', 'none')
      const isMobile = $('#navHideButton').css('display') === 'inline-flex'
      if (isMobile) {
        $('#navHideButton').click()
      } else {
        $("[aria-labelledby='" + menuLabel + "']").css('display', 'none')
        $('#navHideButton').click()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url("../assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.css");
.dropdown-reverse {
  left: auto !important;
  right: 0px !important;
}

.force-hide {
  display: none !important;
}

#userProfileDropdown {
  top: 65px;
}
</style>
