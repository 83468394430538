const state = {
  position_list: [
    {
      name: 'engineering',
      category: 'Engineering & Architecture',
      image_url: 'team-engineering-bg.svg',
      cta_title: 'What is it like to work at beNovelty?',
      cta_description: "Great question. We can't wait to show you more!",
      position: [
        {
          title: 'Full Stack Developer / Software Engineer',
          url: 'full-stack-developer-software-engineer',
          about: '<p>We are looking for Full Stack Developer / Software Engineer to assist on our projects around Hong Kong API Economy.</p><ul><li>Working with Development Manager and develop full stack applications around API platforms and technologies<li>Research and study the use of public / partner / private APIs to create additional business values<li>White-boarding a fix to a scaling problem and turning the solution into reality<li>Building DevOps Tools to make all of our developers happy (including yourself)<li>Troubleshooting and solving challenging incidents<li>Candidate with good attitude but less relevant skills & experience will start from the position of “Associate Full Stack Developer / Associate Software Engineer”</li></ul><p>You will:</p><ul><li>be the first batch of people experiencing impact from the Hong Kong API Economy<li>be familiar with AGILE project management and the latest technology consulting & research practices<li>be exposed to end-to-end development projects<li>be coached by former IBM Technology Thought Leader & Consultant directly<li>build solid knowledge of both business and technical aspects of system design and implementation</ul>',
          requirement: '<ul><li>Strong interests and basic knowledge in one the following technology areas:</li><ul><li>Frontend Application Development in VueJS / React / Angular / Ionic etc.</li><li>Backend Application Development in JavaScript / Node.js / Loopback / Python etc.</li><li>Cloud Architecture in AWS / Alibaba Cloud / Azure Cloud etc.</li></ul><li>Basic understanding of REST API and Web Services Development<li>Strong sense of humor and a good team player<li>Strong logical thinking and data sensitivity<li>Can-do attitude and confidence to strive for excellence</ul>'
        },
        {
          title: 'Web / Mobile Developer',
          url: 'web-mobile-developer',
          about: '<p>We are looking for a Web / Mobile Developer to support our in-house product development and projects.</p><ul><li>Working with Development Manager and develop mobile / web frontend application across different projects</li><li>White-boarding a fix to a scaling problem and turning the solution into reality</li><li>Building DevOps Tools to make all of our developers happy (including yourself)</li><li>Troubleshooting and solving challenging incidents</li></ul><p>You will:</p><ul><li>be exposed to end-to-end development projects</li><li>be open to opportunities to quickly grow as a solid full-stack app developer in longer-term</li><li>be coached by former IBM Thought Leader in Mobile Enterprise directly</li><li>develop expertise in the latest mobile application</li><li>be familiar with AGILE practice</li><li>develop a highly technical background</li><li>build solid knowledge of both business and technical aspects of system design and implementation</li></ul>',
          requirement: '<ul><li>Strong interests and basic knowledge in the following technology areas:</li><ul><li>Web front-end Technologies in HTML5 / CSS3 / JavaScript</li><li>Experience in AngularJS / ReactJS is a plus</li><li>Mobile development in Ionic Framework/Native iOS (Objective-C or Swift)/Android</li></ul><li>Basic understanding of UI/UX design</li><li>Strong sense of humor and a good team player</li><li>Can-do attitude and confidence to strive for excellence</li></ul>'
        },
        {
          title: 'Junior Data Scientist',
          url: 'junior-data-scientist',
          about: '<p>We are looking for a Junior Data Scientist to assist on our data projects around Hong Kong API Economy (HKMA Open API, Open Data etc). You will first work with our core team for the following tasks:</p><ul><li>Data acquisition</li><li>Data transformation</li><li>Application development</li></ul><p>And later on with tasks regarding:</p><ul><li>Data quality check</li><li>Data analysis</li><li>Data visualization</li></ul><p>You will:</p><ul><li>be the first batch of people experiencing impact from the Hong Kong API Economy<li>be familiar with AGILE project management and the latest technology consulting & research practices</li><li>be exposed to end-to-end development projects<li>be coached by a Senior Data Scientist during the project</li></ul><p>Candidate with good attitude but less relevant skills & experience will start from the position of "Data Analyst"</p>',
          requirement: '<ul><li>We are happy to work with candidates with strong sense of responsibility and fast learning ability</li><li>You need to have experience in any one of the following programming languages:</li><ul><li>Python / R / Julia / SageMath / C++ / Fortran</li></ul><li>Candidate should possess strong analytic skills, solid reasoning and good attitude and ability to learn fast. This can be proved by having either:</li><ul><li>a bachelor degree of at minimum 2:1 or any equivalent class;</li><li>a research master degree;</li><li>a doctoral degree;</li><li>or a excellent major/specialisation from the following discipline:</li><li>Mathematics / Computer Science / Physics / Statistics or any engineering discipline that covers at least 3 of the following essential topics</li><ul><li>Multivariable calculus, Linear algebra, Matrix algebra, Basic algorithms, Complexity analysis, Monte carlo method</li></ul></ul><li>Experience in the following would definitely be an advantage:</li><ul><li>Data cleansing</li><li>Grid computing</li><li>Unix/Linux</li><li>Unsupervised learning</li></ul><li>You are not required to have any application development experience. Our team can help you for the adaption</ul>'
        },
        {
          title: 'Cloud / System Engineer',
          url: 'cloud-system-engineer',
          about: '<p>We are looking for a Cloud / System Engineer to build a solid cloud foundation for our projects around Hong Kong API Economy.</p><ul><li>Working with our CTO in developing our AWS / Alibaba / Azure cloud foundation for different complex use cases on cloud</li><li>White-boarding a fix to a scaling problem (beyond Hong Kong region) and turning the solution into reality</li><li>Building DevOps Tools to make all of our developers happy (including yourself)</li><li>Troubleshooting and solving challenging incidents</li><li>Candidate with good attitude but less relevant skills & experience will start from the position of “Associate Cloud / System Engineer”</li></ul><p>You will:</p><ul><li>be able to obtain skill level of <strong>“AWS Certified Solutions Architect – Associate”</strong> or equivalent in a year</li><li>be sponsored by the company for professional certification if you have the right attitude</li><li>be coached by former IBM Thought Leader</li><li>build solid knowledge of both business and technical aspects of system design and implementation</li></ul>',
          requirement: '<ul><li>Strong interests and basic knowledge in the following technology areas:</li><ul><li>AWS Cloud / Alibaba / Azure Cloud Technologies</li><li>3-tier architecture or containerization (container-based virtualization)</li><li>Backend programming technologies in Node.js / Python / Java etc.</li></ul><li>Basic understanding of REST API and Web Services Development</li><li>Strong sense of humor and a good team player</li><li>Strong logical thinking and data sensitivity</li><li>Can-do attitude and confidence to strive for excellence</li></ul>'
        },
        {
          title: 'Solution Architect',
          url: 'solution-architect',
          about: '<p>We are looking for a Solution Architect to build a solid cloud foundation for our platforms & projects around Hong Kong API Economy.</p><ul><li>Working with our CTO in developing our AWS / Alibaba / Azure cloud foundation for different complex use cases on cloud</li><li>Building and integrating information systems to meet the company’s needs</li><li>Assessing the systems architecture currently in place and working with technical staff to recommend solutions to improve it</li><li>Resolving technical problems as they arise</li><li>Providing supervision and guidance to development teams</li><li>Continually researching current and emerging technologies and proposing changes where needed</li><li>Informing various stakeholders about any problems with the current technical solutions being implemented</li><li>Assessing the business impact that certain technical choices have</li><li>Providing updates to stakeholders on product development processes, costs, and budgets</li><li>Candidate with good attitude but less relevant skills & experience will start from the position of “Associate Solution Architect”</li></ul><p>You will:</p><ul><li>be able to obtain skill level of <strong>“AWS Certified Solutions Architect – Professional”</strong> or equivalent</li><li>be sponsored by the company for professional certification if you have the right attitude</li><li>be coached by former IBM Thought Leader</li><li>build solid knowledge of both business and technical aspects of system design and implementation</li></ul>',
          requirement: '<ul><li>Strong interests and basic knowledge in the following technology areas:</li><ul><li>AWS Cloud / Alibaba / Azure Cloud Technologies</li><li>3-tier architecture or containerization (container-based virtualization)</li><li>Backend programming technologies in Node.js / Python / Java etc.</li></ul><li>Basic understanding of REST API and Web Services Development</li><li>Strong sense of humor and a good team player</li><li>Strong logical thinking and data sensitivity</li><li>Can-do attitude and confidence to strive for excellence</li></ul>'
        },
        {
          title: 'Associate API Architect',
          url: 'associate-api-architect',
          about: '<p>We are looking for an Associate Architect to build a solid API foundation for our platforms & projects around Hong Kong API Economy.</p><ul><li>Working with our CTO in developing our AWS / Alibaba / Azure cloud foundation for different complex use cases on cloud</li><li>Quickly evaluate and build PoCs technology options, remove impediments and be a catalyst to engineering team</li><li>Assessing the API systems architecture currently in place and working with technical staff to recommend solutions to improve it</li><li>Continually researching current and emerging API technologies and proposing changes where needed</li><li>Supporting the communication with various stakeholders about any problems with the current technical solutions being implemented</li><li>Supporting the communication with various stakeholders on product development processes, costs, and budgets</li><li>Supporting the assessment to the business impact that certain technical choices have</li><li>Candidate with good attitude but less relevant skills & experience will start from the position of “Software Engineer / System Engineer”</li></ul><p>You will:</p><ul><li>be able to obtain skill level of <strong>“AWS Certified Solutions Architect – Professional”</strong> or equivalent in 2 years</li><li>be sponsored by the company for professional certification if you have the right attitude</li><li>be coached by former IBM Thought Leader</li><li>build solid knowledge of both business and technical aspects of system design and implementation</li></ul>',
          requirement: '<ul><li>Worked in a RESTful APIs environment</li><ul><li>Familiar with Microservices, Kubernetes, Docker, Azure / AWS / GCP / Alibaba Cloud and Data center operations</li><li>Working knowledge of ReactJS, MongoDB, Redis, Golang, JSON, JWT (JSON Web Token) is required</li><li>Solid understanding of the Unix & Linux ecosystem</li><li>Familiar with Agile development methodology</li></ul><li>Basic understanding of REST API and Web Services Development</li><li>Strong sense of humor and a good team player</li><li>Strong logical thinking and data sensitivity</li><li>Can-do attitude and confidence to strive for excellence</li></ul>'
        },
        {
          title: 'Associate Cloud Architect',
          url: 'associate-cloud-architect',
          about: '<p>We are looking for an Associate Cloud Architect to build a solid API foundation for our platforms & projects around Hong Kong API Economy.</p><ul><li>Working with our CTO in developing our AWS / Alibaba / Azure Cloud & DevOps foundation for different complex use cases on cloud</li><li>Quickly evaluate and build PoCs technology options, remove impediments and be a catalyst to engineering team</li><li>Assessing the API systems architecture currently in place and working with technical staff to recommend solutions to improve it</li><li>Continually researching current and emerging Cloud & DevOps technologies and proposing changes where needed</li><li>Supporting the communication with various stakeholders about any problems with the current technical solutions being implemented</li><li>Supporting the communication with various stakeholders on product development processes, costs, and budgets</li><li>Supporting the assessment to the business impact that certain technical choices have</li><li>Candidate with good attitude but less relevant skills & experience will start from the position of “Software Engineer / System Engineer”</li></ul><p>You will:</p><ul><li>be able to obtain skill level of <strong>“AWS Certified Solutions Architect – Professional”</strong> or equivalent in 2 years</li><li>be sponsored by the company for professional certification if you have the right attitude</li><li>be coached by former IBM Thought Leader</li><li>build solid knowledge of both business and technical aspects of system design and implementation</li></ul>',
          requirement: '<ul><li>Worked in a cloud native environment</li><ul><li>Familiar with Microservices, Kubernetes, Docker, Azure/AWS/GCP/Alibaba Cloud and Data center operations</li><li>Working knowledge of ReactJS, MongoDB, Redis, Golang, JSON, JWT (JSON Web Token) is required</li><li>Solid understanding of the Unix & Linux ecosystem</li><li>Familiar with Agile development methodology</li></ul><li>Basic understanding of REST API and Web Services Development</li><li>Basic understanding of DevOps & Automation Development</li><li>Strong sense of humor and a good team player</li><li>Strong logical thinking and data sensitivity</li><li>Can-do attitude and confidence to strive for excellence</li></ul>'
        }
      ]
    },
    {
      name: 'business',
      category: 'Business & Sales',
      image_url: 'team-business-bg.svg',
      cta_title: 'What is it like to work at beNovelty?',
      cta_description: "Great question. We can't wait to show you more!",
      position: [
        {
          title: 'Business Analyst',
          url: 'business-analyst',
          about: '<p>We are looking for Business Analyst (with stronger business domain knowledge) to assist on our fast growing API platform and projects around Hong Kong API Economy.</p><ul><li>Explore and identify potential business client needs and converting them into platform and project requirements</li><li>Support the essential business and project activities to ensure platform and project success</li><li>Scheduling, tracking & verifying project items under supervision of managers</li><li>Actively engaging with the team and contributing anything that can ensure project’s success</li><li>Candidate with good attitude but less relevant skills & experience will start from the position of “Associate Business Analyst”</li></ul><p>You will:</p><ul><li>be the first batch of people contributing to the Hong Kong API Economy</li><li>be familiar with AGILE project management and the latest technology consulting & research practices</li><li>be exposed to end-to-end project engagement</li><li>be coached by experienced technology consultants directly</li><li>develop expertise in technology & business consulting</li></ul>',
          requirement: '<ul><li>Good communication skills in Cantonese and English</li><li>Strong sense of humor and a good team player</li><li>Can-do attitude and confidence to strive for excellence</li><li>Strong logical thinking and data sensitivity</li><li>Basic IT Knowledge</li></ul>'
        },
        {
          title: 'Business Development Manager',
          url: 'business-development-manager',
          about: '<p>We are looking for Business Development Manager to lead on our fast growing business development activities around Hong Kong API Economy.</p><ul><li>Develop a growth strategy focused both on financial gain and customer satisfaction</li><li>Conduct research to identify new markets and customer needs</li><li>Arrange business meetings with prospective clients</li><li>Promote the company’s products/services addressing or predicting clients’ objectives</li><li>Provide trustworthy feedback and after-sales support</li><li>Build long-term relationships with new and existing customers</li><li>Develop entry level staff into valuable salespeople</li><li>Candidate with good attitude but less relevant skills & experience will start from the position of "Associate Business Development Manager"</li></ul><p>You will:</p><ul><li>be the first batch of people contributing to the Hong Kong API Economy</li><li>be exposed to end-to-end business development engagement</li><li>be coached by experienced technology consultants directly</li><li>develop expertise in technology & business consulting</li></ul>',
          requirement: '<ul><li>Good communication skills in Cantonese and English</li><li>Strong sense of humor and a good team player</li><li>Proven working experience as a business development manager, sales executive or a relevant role</li><li>Proven sales track record</li><li>Experience in customer support is a plus</li><li>Proficiency in MS Office and CRM software (e.g. Salesforce)</li><li>Time management and planning skills</li></ul>'
        },
        {
          title: 'Marketing & Corporate Communication',
          url: 'marketing-corporate-communication',
          about: '<p>We are looking for Marketing & Corporate Communication to support on our fast growing API platform around Hong Kong API Economy.</p><ul><li>Promote our products and services during events</li><li>Coordinate the design of promotional material and distribute in online and offline channels</li><li>Advertise our company and products/services on various media</li><li>Plan interviews and press conferences</li><li>Produce marketing copy for our website</li><li>Craft and send regular newsletters with company updates</li><li>Track ROI for marketing campaigns</li><li>Join social media groups and professional platforms to discuss industry-related topics</li><li>Monitor corporate website and social media pages and address clients’ queries</li><li>Network with industry experts and potential clients to drive brand awareness</li><li>Gather customer feedback to inform sales and product teams</li></ul>',
          requirement: '<ul><li>Proven work experience as a Marketing Communications Specialist or similar role</li><li>Familiarity with B2B and B2C advertising campaigns</li><li>Hands-on experience with web content management tools, like WordPress</li><li>Knowledge of SEO and Google Analytics</li><li>Experience with marketing campaigns on social media</li><li>Excellent verbal and written communication skills</li><li>Good presentation skills</li><li>Strong sense of humor and a good team player</li><li>Time management and planning skills</li></ul>'
        },
        {
          title: 'Technical Account Manager',
          url: 'technical-account-manager',
          about: '<p>We are looking for Technical Account Manager to support on our fast growing API platform around Hong Kong API Economy.</p><ul><li>Provide technical support for customers to support pre-sales and post-sales processes</li><li>Address all product-related queries on time</li><li>Train customers to use products effectively</li><li>Provide developers with customers’ feedback to help identify potential new features or products</li><li>Report on product performance</li><li>Identify solutions to reduce support costs</li><li>Analyze customers’ needs and suggest upgrades or additional features to meet their requirements</li><li>Liaise with the sales department to win new business and increase sales</li><li>Establish best practices</li><li>Keep track of sales performance metrics</li><li>Candidate with good attitude but less relevant skills & experience will start from the position of “Associate Technical Account Manager”</li></ul>',
          requirement: '<ul><li>Proven work experience as a Technical account manager</li><li>Solid technical background with hands on experience in digital technologies</li><li>Familiarity with software and front-end development</li><li>An ability to gasp customers’ needs and suggest timely solutions</li><li>Excellent verbal and written communication skills in Cantonese and English</li><li>Strong analytical and problem-solving skills</li><li>Strong sense of humor and a good team player</li><li>Time management and planning skills</li></ul>'
        }
      ]
    },
    {
      name: 'product',
      category: 'Product & Growth',
      image_url: 'team-product-bg.svg',
      cta_title: 'What is it like to work at beNovelty?',
      cta_description: "Great question. We can't wait to show you more!",
      position: [
        {
          title: 'API Evangelist / Community Manager',
          url: 'api-evangelist-community-manager',
          about: '<p>We are looking for API Evangelist / Community Manager to support on our fast growing API platform around Hong Kong API Economy.</p><ul><li>Set and implement social media and communication campaigns to align with API marketing strategies</li><li>Provide engaging text, image and video content for social media accounts</li><li>Respond to comments and customer queries in a timely manner</li><li>Monitor and report on feedback and online reviews</li><li>Organize and participate in events to build API community and boost brand awareness</li><li>Coordinate with Marketing, PR and Communications teams to ensure brand consistency</li><li>Liaise with Development and Sales departments to stay updated on new products and features</li><li>Build relationships with customers, potential customers, industry professionals and journalists</li><li>Stay up-to-date with digital technology trends</li></ul>',
          requirement: '<ul><li>Proven work experience as a community manager</li><li>Experience launching community initiatives (e.g. building an online forum, launching an ambassador program, creating an event series and writing an email newsletter)</li><li>Ability to identify and track relevant community metrics (e.g. repeat attendance at events)</li><li>Excellent verbal communication skills</li><li>Excellent writing skills</li><li>Hands on experience with social media management for brands</li><li>Ability to interpret website traffic and online customer engagement metrics</li><li>Knowledge of online marketing and marketing channels</li><li>Attention to detail and ability to multitask</li><li>Strong sense of humor and a good team player</li><li>Time management and planning skills</li></ul>'
        },
        {
          title: 'Product Manager',
          url: 'product-manager',
          about: '<p>We are looking for Product Manager to support on our fast growing API platform around Hong Kong API Economy.</p><ul><li>Gain a deep understanding of customer experience, identify and fill product gaps and generate new ideas that grow market share, improve customer experience and drive growth</li><li>Create buy-in for the product vision both internally and with key external partners</li><li>Develop product pricing and positioning strategies</li><li>Translate product strategy into detailed requirements and prototypes</li><li>Scope and prioritize activities based on business and customer impact</li><li>Work closely with engineering teams to deliver with quick time-to-market and optimal resources</li><li>Drive product launches including working with public relations team, executives, and other product management team members</li><li>Evaluate promotional plans to ensure that they are consistent with product line strategy and that the message is effectively conveyed</li><li>Act as a product evangelist to build awareness and understanding</li><li>Represent the company by visiting customers to solicit feedback on company products and services</li><li>Candidate with good attitude but less relevant skills & experience will start from the position of "Associate Product Manager"</li></ul>',
          requirement: '<ul><li>Proven work experience in product management or as an associate product manager</li><li>Proven track record of managing all aspects of a successful product throughout its lifecycle</li><li>Proven ability to develop product and marketing strategies and effectively communicate recommendations to executive management</li><li>Solid technical background with understanding and/or hands-on experience in software development and web technologies</li><li>Strong problem solving skills and willingness to roll up one’s sleeves to get the job</li><li>Skilled at working effectively with cross functional teams in a matrix organization</li><li>Good communication skills in Cantonese and English</li><li>Strong sense of humor and a good team player</li><li>Time management and planning skills</li></ul>'
        }
      ]
    },
    {
      name: 'ux',
      category: 'Creative & User Experience',
      image_url: 'team-ux-bg.svg',
      cta_title: 'What is it like to work at beNovelty?',
      cta_description: "Great question. We can't wait to show you more!",
      position: [
        {
          title: 'Web / UX Designer',
          url: 'web-ux-designer',
          about: '<p>We are looking for a Web / UX Designer to design stunning web and mobile applications for our product</p><ul><li>Working with our designer lead, design and create user interfaces for our mobile and web applications</li><li>Working with our designer lead, design and create website for our app promotion purpose</li><li>Conduct Web / App UI review based on the team user experience practices</li></ul><p>You will:</p><ul><li>be exposed to end-to-end production of websites and mobile app projects</li><li>be coached by Certified UX Specialist</li><li>be familiar with AGILE practice</li><li>build solid knowledge of both business and design aspects of web and mobile app design and implementation</li></ul>',
          requirement: '<ul><li>Solid knowledge in Web and Mobile App UI Design</li><li>Basic knowledge in HTML5 / CSS3</li><li>Knowledge of design tools such as Sketch, Photoshop, Illustrator etc.</li><li>Basic understanding in UX and Human Interface practice</li><li>Strong sense of humor and a good team player</li><li>Can-do attitude and confidence to strive for excellence</li><li>Previous work examples / portfolio in web design, app design or graphics design is a PLUS</li></ul>'
        }
      ]
    },
    {
      name: 'delivery',
      category: 'Delivery Excellence & Customer Experience',
      image_url: 'team-delivery-bg.svg',
      cta_title: 'What is it like to work at beNovelty?',
      cta_description: "Great question. We can't wait to show you more!",
      position: [
        {
          title: 'Project Officer',
          url: 'project-officer',
          about: '<p>We are looking for Project Officer (with stronger project domain knowledge) to assist on our fast growing API platform and projects around Hong Kong API Economy.</p><ul><li>Explore and identify potential business client needs and converting them into platform and project requirements</li><li>Support the essential business and project activities to ensure platform and project success</li><li>Scheduling, tracking & verifying project items under supervision of managers</li><li>Actively engaging with the team and contributing anything that can ensure project’s success</li><li>Candidate with good attitude but less relevant skills & experience will start from the position of “Associate Project Officer”</li></ul><p>You will:</p><ul><li>be the first batch of people contributing to the Hong Kong API Economy</li><li>be familiar with AGILE project management and the latest technology consulting & research practices</li><li>be exposed to end-to-end project engagement</li><li>be coached by experienced technology consultants directly</li><li>develop expertise in technology & business consulting</li></ul>',
          requirement: '<ul><li>Good communication skills in Cantonese and English</li><li>Strong sense of humor and a good team player</li><li>Can-do attitude and confidence to strive for excellence</li><li>Strong logical thinking and data sensitivity</li><li>Basic IT Knowledge</li></ul>'
        }
      ]
    },
    {
      name: 'internship',
      category: 'Student Internship',
      image_url: 'team-internship-bg.svg',
      cta_title: 'We received a great number of returning interns every year',
      cta_description: 'Want to know why our interns love beNovelty?',
      position: [
        {
          title: 'Cloud / System Engineer (Internship)',
          url: 'cloud-system-engineer-internship',
          about: '<p>We are looking for a Cloud / System Engineer (Internship) to build a solid cloud foundation for our projects around Hong Kong API Economy.</p><ul><li>Working with our CTO in developing our AWS / Alibaba / Azure cloud foundation for different complex use cases on cloud</li><li>White-boarding a fix to a scaling problem (beyond Hong Kong region) and turning the solution into reality</li><li>Building DevOps Tools to make all of our developers happy (including yourself)</li><li>Troubleshooting and solving challenging incidents</li><li>Candidate with good attitude but less relevant skills & experience will start from the position of "Associate Cloud / System Engineer (Internship)”</li></ul><p>You will:</p><ul><li>be able to obtain skill level of <strong>“AWS Certified Solutions Architect – Associate”</strong> or equivalent in a year</li><li>be sponsored by the company for professional certification if you have the right attitude</li><li>be coached by former IBM Thought Leader</li><li>build solid knowledge of both business and technical aspects of system design and implementation</li></ul>',
          requirement: '<ul><li>Strong interests and basic knowledge in the following technology areas:</li><ul><li>AWS Cloud / Alibaba / Azure Cloud Technologies</li><li>3-tier architecture or containerization (container-based virtualization)</li><li>Backend programming technologies in Node.js / Python / Java etc.</li></ul><li>Basic understanding of REST API and Web Services Development</li><li>Strong sense of humor and a good team player</li><li>Strong logical thinking and data sensitivity</li><li>Can-do attitude and confidence to strive for excellence</li></ul>'
        },
        {
          title: 'Full Stack Developer / Software Engineer (Internship)',
          url: 'full-stack-developer-software-engineer-internship',
          about: '<p>We are looking for a Full Stack Developer / Software Engineer (Internship) to assist on our projects around Hong Kong API Economy.</p><ul><li>Working with Development Manager and develop full stack applications around API platforms and technologies</li><li>Research and study the use of public / partner / private APIs to create additional business values</li><li>White-boarding a fix to a scaling problem and turning the solution into reality</li><li>Building DevOps Tools to make all of our developers happy (including yourself)</li><li>Troubleshooting and solving challenging incidents</li><li>Candidate with good attitude but less relevant skills & experience will start from the position of “Associate Full Stack Developer / Associate Software Engineer (Internship)”</li></ul><p>You will:</p><ul><li>be the first batch of people experiencing impact from the Hong Kong API Economy</li><li>be familiar with AGILE project management and the latest technology consulting & research practices</li><li>be exposed to end-to-end development projects</li><li>be coached by former IBM Technology Thought Leader & Consultant directly</li><li>build solid knowledge of both business and technical aspects of system design and implementation</li></ul>',
          requirement: '<ul><li>Strong interests and basic knowledge in one of following technology areas:</li><ul><li>Frontend Application Development in VueJS / React / Angular / Ionic etc.</li><li>Backend Application Development in JavaScript / Node.js / Loopback / Python etc.</li><li>Cloud Architecture in AWS / Alibaba Cloud / Azure Cloud etc.</li></ul><li>Basic understanding of REST API and Web Services Development</li><li>Strong sense of humor and a good team player</li><li>Strong logical thinking and data sensitivity</li><li>Can-do attitude and confidence to strive for excellence</li></ul>'
        },
        {
          title: 'Web / Mobile Developer (Internship)',
          url: 'web-mobile-developer-internship',
          about: '<p>We are looking for a Web / Mobile Developer (Internship) to support our in-house product development and projects.</p><ul><li>Working with Development Manager and develop mobile / web frontend application across different projects</li><li>White-boarding a fix to a scaling problem and turning the solution into reality</li><li>Building DevOps Tools to make all of our developers happy (including yourself)</li><li>Troubleshooting and solving challenging incidents</li></ul><p>You will:</p><ul><li>be exposed to end-to-end development projects</li><li>be open to opportunities to quickly grow as a solid full-stack app developer in longer-term</li><li>be coached by former IBM Thought Leader in Mobile Enterprise directly</li><li>develop expertise in the latest mobile application</li><li>be familiar with AGILE practice</li><li>develop a highly technical background</li><li>build solid knowledge of both business and technical aspects of system design and implementation</li></ul>',
          requirement: '<ul><li>Strong interests and basic knowledge in the following technology areas:</li><ul><li>Web front-end Technologies in HTML5 / CSS3 / JavaScript</li><li>Experience in AngularJS / ReactJS is a plus</li><li>Mobile development in Ionic Framework/Native iOS (Objective-C or Swift) / Android</li></ul><li>Basic understanding of UI/UX design</li><li>Strong sense of humor and a good team player</li><li>Can-do attitude and confidence to strive for excellence</li></ul>'
        },
        {
          title: 'Marketing & Corporate Communication (Internship)',
          url: 'marketing-corporate-communication-internship',
          about: '<p>We are looking for Marketing & Corporate Communication (Internship) to support on our fast growing API platform around Hong Kong API Economy.</p><ul><li>Promote our products and services during events</li><li>Coordinate the design of promotional material and distribute in online and offline channels</li><li>Advertise our company and products/services on various media</li><li>Plan interviews and press conferences</li><li>Produce marketing copy for our website</li><li>Craft and send regular newsletters with company updates</li><li>Track ROI for marketing campaigns</li><li>Join social media groups and professional platforms to discuss industry-related topics</li><li>Monitor corporate website and social media pages and address clients’ queries</li><li>Network with industry experts and potential clients to drive brand awareness</li><li>Gather customer feedback to inform sales and product teams</li></ul>',
          requirement: '<ul><li>Proven work experience as a Marketing Communications Specialist or similar role</li><li>Familiarity with B2B and B2C advertising campaigns</li><li>Hands-on experience with web content management tools, like WordPress</li><li>Knowledge of SEO and Google Analytics</li><li>Experience with marketing campaigns on social media</li><li>Excellent verbal and written communication skills</li><li>Good presentation skills</li><li>Strong sense of humor and a good team player</li><li>Time management and planning skills</li></ul>'
        },
        {
          title: 'Project Administrator (Internship)',
          url: 'project-administrator-internship',
          about: '<p>We are looking for Project Administrator (Internship) to assist on our fast growing API platform and projects around Hong Kong API Economy.</p><ul><li>Explore and identify potential business client needs and converting them into platform and project requirements</li><li>Support the essential business and project activities to ensure platform and project success</li><li>Scheduling, tracking & verifying project items under supervision of managers</li><li>Actively engaging with the team and contributing anything that can ensure project’s success</li></ul><p>You will:</p><ul><li>be the first batch of people contributing to the Hong Kong API Economy</li><li>be familiar with AGILE project management and the latest technology consulting & research practices</li><li>be exposed to end-to-end project engagement</li><li>be coached by experienced technology consultants directly</li><li>develop expertise in technology & business consulting</li></ul>',
          requirement: '<ul><li>Good communication skills in Cantonese and English</li><li>Strong sense of humor and a good team player</li><li>Can-do attitude and confidence to strive for excellence</li><li>Strong logical thinking and data sensitivity</li><li>Basic IT Knowledge</li></ul>'
        },
        {
          title: 'API Evangelist / Community Manager (Internship)',
          url: 'api-evangelist-community-manager-internship',
          about: '<p>We are looking for API Evangelist / Community Manager (Internship) to support on our fast growing API platform around Hong Kong API Economy.</p><ul><li>Set and implement social media and communication campaigns to align with API marketing strategies</li><li>Provide engaging text, image and video content for social media accounts</li><li>Respond to comments and customer queries in a timely manner</li><li>Monitor and report on feedback and online reviews</li><li>Organize and participate in events to build API community and boost brand awareness</li><li>Coordinate with Marketing, PR and Communications teams to ensure brand consistency</li><li>Liaise with Development and Sales departments to stay updated on new products and features</li><li>Build relationships with customers, potential customers, industry professionals and journalists</li><li>Stay up-to-date with digital technology trends</li></ul>',
          requirement: '<ul><li>Proven work experience as a community manager</li><li>Experience launching community initiatives (e.g. building an online forum, launching an ambassador program, creating an event series and writing an email newsletter)</li><li>Ability to identify and track relevant community metrics (e.g. repeat attendance at events)</li><li>Excellent verbal communication skills</li><li>Excellent writing skills</li><li>Hands on experience with social media management for brands</li><li>Ability to interpret website traffic and online customer engagement metrics</li><li>Knowledge of online marketing and marketing channels</li><li>Attention to detail and ability to multitask</li><li>Strong sense of humor and a good team player</li><li>Time management and planning skills</li></ul>'
        },
        {
          title: 'Web / UX Designer (Internship)',
          url: 'web-ux-designer-internship',
          about: '<p>We are looking for a Web / UX Designer (Internship) to design stunning web and mobile applications for our product</p><ul><li>Working with our designer lead, design and create user interfaces for our mobile and web applications</li><li>Working with our designer lead, design and create website for our app promotion purpose</li><li>Conduct Web / App UI review based on the team user experience practices</li></ul><p>You will:</p><ul><li>be exposed to end-to-end production of websites and mobile app projects</li><li>be coached by Certified UX Specialist</li><li>be familiar with AGILE practice</li><li>build solid knowledge of both business and design aspects of web and mobile app design and implementation</li></ul>',
          requirement: '<ul><li>Solid knowledge in Web and Mobile App UI Design</li><li>Basic knowledge in HTML5 / CSS3</li><li>Knowledge of design tools such as Sketch, Photoshop, Illustrator etc.</li><li>Basic understanding in UX and Human Interface practice</li><li>Strong sense of humor and a good team player</li><li>Can-do attitude and confidence to strive for excellence</li><li>Previous work examples / portfolio in web design, app design or graphics design is a PLUS</li></ul>'
        }
      ]
    },
    {
      name: 'accounting',
      category: 'Finance & Accounting',
      image_url: 'team-delivery-bg.svg',
      cta_title: 'What is it like to work at beNovelty?',
      cta_description: "Great question. We can't wait to show you more!",
      position: [
        {
          title: 'Part-time Accounting Assistant',
          url: 'part-time-accounting-assistant',
          about: '<ul><li>Assist supervisor to handle general accounting duties and book-keeping with respect to payable, receivable and general ledgers, invoices and receipts verification</li><li>Provide support in preparing journal vouchers, financial reports and account reconciliation for month-end closing and year-end closing</li><li>Perform any ad hoc duties as assigned by the supervisor</li></ul>',
          requirement: '<ul><li>University / VTC students in Finance or Accounting (Yr 2 or Yr 3)</li><li>Part time accounting experience will be an advantage</li><li>Proficiency in MS office and accounting software (experience in Xero is an advantage)</li><li>Good in communications and documentation skills</li></ul>'
        }
      ]
    }
  ],
  scrollToList: false
}

const mutations = {
  //   SET_PROFILE: (state, profile) => {
  //     state.userProfile = profile
  //   }
}

export default {
  namespaced: true,
  state,
  mutations
}

