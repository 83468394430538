const getters = {
  careers: state => state.careers,
  events: state => state.events,
  partners: state => state.partners,
  practices: state => state.practices,
  resources: state => state.resources,
  apidays2020recap: state => state.apidays2020recap,
  apidays2021recap: state => state.apidays2021recap,
  apidays2022recap: state => state.apidays2022recap,
  user: state => state.user,
  showDummy: state => state.app.showDummy
}

export default getters

