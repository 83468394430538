<template>
  <div id="contact-us" class="position-relative" style="background-color:#f5f9fc;">
    <div class="container position-relative z-index-2">

      <!-- ========== Terms & Copyright ========== -->
      <div class="space-2">
        <!-- <div class="mb-4">
          <router-link :to="{ path: '/' }">
            <img class="brand" src="@/assets/svg/logos/logo-white.svg">
          </router-link>
        </div> -->
        <div class="row align-items-md-center mb-6">
          <!-- Terms -->
          <div class="col-md-5 z-index-999">
            <router-link class="nav-link mb-4 mb-md-0 pl-0" :to="{ path: '/' }">
              <img class="brand" src="@/assets/svg/logos/logo.svg">
            </router-link>
          </div>
          <!-- End Terms -->

          <!-- Social Networks -->
          <div class="col-md-7 text-md-right z-index-999">
            <ul class="nav nav-sm nav-x-sm align-items-center justify-content-md-end">
              <li class="nav-item">
                <router-link class="nav-link pl-0" :to="{ path: '/pricing' }">Pricing</router-link>
              </li>
              <li class="nav-item opacity mx-2">/</li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/contact' }">Contact</router-link>
              </li>
              <li class="nav-item opacity mx-2">/</li>
              <li class="nav-item">
                <a class="nav-link" href="https://www.benovelty.com/fabrixapi/terms-of-use" target="_blank">Terms of Use</a>
              </li>
              <li class="nav-item opacity mx-2">/</li>
              <li class="nav-item">
                <a class="nav-link" href="https://www.benovelty.com/fabrixapi/privacy-policy" target="_blank">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <!-- End Social Networks -->
        </div>

        <!-- Copyright -->
        <div class="z-index-999">
          <p class="small">FabriXAPI is the NextGen of OpenAPIHub — the award-winning API platform by beNovelty</p>
          <p class="small">© {{ new Date().getFullYear() }} FabriXAPI. Build with <a href="https://www.benovelty.com/" target="_blank">beNovelty</a> spirit. All rights reserved.</p>
        </div>
        <!-- End Copyright -->
      </div>
      <!-- ========== End Terms & Copyright ========== -->
    </div>
  </div>
</template>

<script>
// import 'bootstrap'
export default {
  name: 'Footer',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
    this.loadScriptBenGeneralForm('https://js.hsforms.net/forms/v2.js', () => {
      const secondScript = document.createElement('script')
      secondScript.innerHTML = `
        hbspt.forms.create({
          portalId: "7091000",
          formId: "13239e83-63b2-4469-8e47-aaf91bdc82d2"
        });
      `
      this.$refs.benGeneralForm.appendChild(secondScript)
    })
  },
  methods: {
    loadScriptBenGeneralForm(url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onreadystatechange = callback
      script.onload = callback
      this.$refs.benGeneralForm.appendChild(script)
    }
  }
}
</script>
