import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import store from '../store'

export const constantRoutes = [
  {
    name: 'index',
    path: '/',
    component: () => import('@/views/index/index.vue')
  },
  {
    name: 'pricing',
    path: '/pricing',
    component: () => import('@/views/pricing/index.vue')
  },
  {
    name: 'contact',
    path: '/contact',
    component: () => import('@/views/contact/index.vue')
  },
  {
    name: 'error',
    path: '/error',
    component: () => import('@/views/error/index.vue')
  },
  {
    name: 'error404',
    path: '*',
    component: () => import('@/views/error404/index.vue')
  },

  {
    name: 'api-portal',
    path: '/api-portal',
    component: () => import('@/views/api-portal/index.vue')
  },
  {
    name: 'api-management',
    path: '/api-management',
    component: () => import('@/views/api-management/index.vue')
  },
  {
    name: 'api-transformations',
    path: '/api-transformations',
    component: () => import('@/views/api-transformations/index.vue')
  },
  {
    name: 'api-monetization',
    path: '/api-monetization',
    component: () => import('@/views/api-monetization/index.vue')
  },
  {
    name: 'apiops-cicd',
    path: '/apiops-cicd',
    component: () => import('@/views/apiops-cicd/index.vue')
  },
  {
    name: 'trust-certification',
    path: '/trust-certification',
    component: () => import('@/views/trust-certification/index.vue')
  },
  {
    name: 'api-hub-community',
    path: '/api-hub-community',
    component: () => import('@/views/api-hub-community/index.vue')
  },
  {
    name: 'api-portal-themes',
    path: '/themes',
    component: () => import('@/views/api-portal-themes/index.vue')
  },
  {
    name: 'developer-management',
    path: '/developer-management',
    component: () => import('@/views/developer-management/index.vue')
  },
  {
    name: 'subscription-management',
    path: '/subscription-management',
    component: () => import('@/views/subscription-management/index.vue')
  },
  {
    name: 'ai-gateway',
    path: '/ai-gateway',
    component: () => import('@/views/ai-gateway/index.vue')
  },

  {
    name: 'whats-new-aug2023',
    path: '/whats-new-aug2023',
    component: () => import('@/views/whats-new/index.vue')
  },
  {
    name: 'whats-new-jan2024',
    path: '/whats-new-jan2024',
    component: () => import('@/views/whats-new-jan2024/index.vue')
  },
  {
    name: 'coupons-detail',
    path: '/coupons/:id',
    component: () => import('@/views/coupons/details')
  },
  {
    name: 'resources',
    path: '/resources',
    component: () => import('@/views/resources/index.vue')
  },
  {
    name: 'provider-crash-course',
    path: '/resources/provider-crash-course',
    component: () => import('@/views/provider-crash-course/index.vue')
  }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()
router.beforeEach(async(to, from, next) => {
  console.log('setShowDummy', true)
  store.dispatch('app/setShowDummy', true)
  next()
})
router.afterEach((to, from) => {
  console.log('setShowDummy', false)
  store.dispatch('app/setShowDummy', false)
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
